.message-success{
    background-color: #ccf1db;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: #008299;
}
.message-error{
    background-color: #fed6dd;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: #951d62;
}
.header-bg-blue{
    background-color: #000080 !important;
    color: white;
    text-align: center;
    font-size: 40px !important;
    border-radius: unset !important;
    padding: 1em;
}
@media screen and (max-width: 500px){
    .header-bg-blue{
        font-size: 30px !important;
    }
}
.job-title{
    font-size: 20px;
}